export class Banking {
    constructor() {
        this.id = "";
        this.user = null;
        this.fullName =  "";
        this.accountType =  "";
        this.accountNumber = 0;
        this.accountHolder =  "";
        this.bankName =  "";
        this.branchCode = 0;
    }
  }