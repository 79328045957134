<template>
  <v-container fluid class="hero">
    <v-row>
      <v-col cols="12" md="6">
        <v-form  ref="BankingCreateForm" v-model="formValid">

          <h1>Banking Details</h1>

          <v-img src="../../assets/card2 1.png" max-width="200"></v-img>

          <v-text-field 
            v-model="bankingModel.accountHolder" 
            label="Card Holder Name"
          ></v-text-field>

          <v-row>
            <v-col cols="6">
              <!-- <v-select
                label="Account Type"
                class="mt-12"
                :items="items"
              ></v-select> -->
              <v-text-field v-model="bankingModel.accountType" label="Account Type"></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field v-model="bankingModel.accountNumber" type="number"  label="Account Number"></v-text-field>
            </v-col>
          </v-row>
          
          <v-row>
            <v-col cols="6">
              <v-text-field v-model="bankingModel.bankName"  label="Bank Name"></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field v-model="bankingModel.branchCode" type="number"  label="Branch Code"></v-text-field>
            </v-col>
          </v-row>

          <!-- <v-btn color="white" class="grey--text" elevation="0">Finish</v-btn> -->
          <v-btn
            :disabled="!formValid"
            color="primary"
            class="mx-2"
            @click="[save()]"
          >
            Save
          </v-btn>
        </v-form>
      </v-col>
      <v-col
        cols="12"
        md="6"
        class="absolute-center"
        v-if="
          $vuetify.breakpoint.md ||
          $vuetify.breakpoint.lg ||
          $vuetify.breakpoint.xl
        "
      >
        <v-row justify="center" align="center">
          <v-avatar class="" size="200" tile>
            <v-img src="../../assets/FairwageLogo.png"></v-img>
          </v-avatar>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { Banking } from "@/models/Banking";
import { gql } from "apollo-boost";

export default {
  components: {},
  name: "Account",
  mounted() {
    this.$emit("update:pageName", this.$options.name);
  },
  data() {
    return {
      tab: 0,
      tabs: ["Credit Card", "EFT"],
      CardholderName: "Mr Martin",
      CardholderExpiryDate: "05/27",
      CardholderNumber: "4000000000000002",
      CardholderCVV: "123",
      selectedItem: 1,
      isLoading: false,
      bankingModel: new Banking,
      formValid: true,
      isEdit: false,
      items: ['Savings', 'Cheque', 'Transmision'],
      id: "",
    };
  },
  apollo: {
    me: {
      query: gql`
        query {
          me {
            id
            firstName
            lastName
          }
        }
      `,
      fetchPolicy: "no-cache",
      result() {
        this.id = this.me.id
      },
    },
    banking: {
      query: gql`
        query Banking($id: ID!){
          banking(id: $id) {
            id
            user
            fullName
            accountType
            accountNumber
            accountHolder
            bankName
            branchCode
          }
        }
      `,
      fetchPolicy: "no-cache",
      variables() {
        return {
          id: this.id,
        };
      },
      result(response) {
        if (response.data.banking.id != "" || null){
          this.isEdit = true
          this.bankingModel.id = this.banking.id;
          this.bankingModel.user = this.banking.user;
          this.bankingModel.accountType = this.banking.accountType;
          this.bankingModel.accountNumber = this.banking.accountNumber;
          this.bankingModel.accountHolder = this.banking.accountHolder;
          this.bankingModel.bankName = this.banking.bankName;
          this.bankingModel.branchCode =  this.banking.branchCode;
        }else{
          this.isEdit = false
        }
      },
    },
  },
  methods: {
    async save() {
      this.isLoading = true;
      const self = this;
      if (!self.$refs.BankingCreateForm.validate()) {
        self.$swal("Error", "Validation failed", "error");
        self.isLoading = false;
        return;
      }
      if (self.isEdit) {
        // update
        await this.updateAccount()
          .then(() => {
            this.$swal("Success!", "Banking details was updated", "success");
          })
          .catch((e) => {
            console.log(e);
            this.$swal(
              "Error!",
              "something went wrong when trying to update the User, check data and try again later!",
              "error"
            );
          });
      } else {
        // create
        await this.createAccount()
          .then(() => {
            this.$swal("Success!", "Banking details was added", "success");
          })
          .catch((e) => {
            console.log(e);
            this.$swal(
              "Error!",
              `Something went wrong when creating the User, check data and try again later!`,
              "error"
            );
          });
      }
      this.isLoading = false;
    },
    async updateAccount() {
      const self = this;
      try {
        const response = await self.$apollo.mutate({
          mutation: gql`
          mutation updateBankingDetails($banking: BankingUpdate!) {
            updateBankingDetails(banking: $banking) {
                id
                updated
              }
            }
          `,
          variables: {
            banking: {
              id: self.bankingModel.id,
              user: self.me.id,
              accountType: self.bankingModel.accountType,
              accountNumber: self.bankingModel.accountNumber,
              accountHolder: self.bankingModel.accountHolder,
              bankName: self.bankingModel.bankName,
              branchCode: self.bankingModel.branchCode,
            },
          },
        });
        if (!response) {
          throw "Something went wrong when updating the Banking details!";
        }
        if (response.errors && response.errors.length > 0) {
          throw response.errors[0];
        }
      } catch (e) {
        throw e.message;
      }
    },
    async createAccount() {
      this.isLoading = true;
      const self = this;
      try {
        // const { user, accountType, accountNumber, accountHolder, bankName, branchCode} = self.banking
        const response = await self.$apollo.mutate({
          mutation: gql`
            mutation addBankingDetails($banking: BankingInput!) {
              addBankingDetails(banking: $banking) {
                id
              }
            }
          `,
          variables: {
            banking: {
              user: self.me.id,
              accountType: self.bankingModel.accountType,
              accountNumber: self.bankingModel.accountNumber,
              accountHolder: self.bankingModel.accountHolder,
              bankName: self.bankingModel.bankName,
              branchCode: self.bankingModel.branchCode,
            },
          },
        });

        if (!response) {
          throw "Something went wrong when adding the Banking details!";
        }
        if (response.errors && response.errors.length > 0) {
          throw response.errors[0];
        }
        if (response && response.data.createUser) {
          self.userModel.id = response?.data?.createUser?.id;
        }

        this.isLoading = false;
      } catch (e) {
        this.$swal("Error!", e.message, "error");
        this.isLoading = false;
      }
    },
  },
  computed: {},
};
</script>

<style scoped>
.herso {
  background: url("../../assets/landing.png") no-repeat center center fixed !important;
  background-size: cover;
  height: 100vh;
  width: 100vw;
}
.v-window.v-item-group.theme--light.v-tabs-items {
  background: #fff !important;
}
.v-sheet.v-card {
  cbackground: #6dd984;
  border-radius: 10px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.theme--light.v-list {
  background: #6dd984;
}

.card-header {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  margin-bottom: 20px;
}

.select-card {
  padding: 10px 30px;
  min-height: 60px;
  position: relative;
}

.top-sect .v-btn {
  width: calc(100% - 67px);
  min-height: 70px;
}

.top-sect .v-btn__content {
  color: #eeecf1 !important;
  font-weight: 700 !important;
  font-size: 20px !important;
  letter-spacing: 0.75px;
}

.top-sect .v-card__actions {
  position: absolute;
  right: 10px;
  top: 10px;
}

.top-sect .v-icon.v-icon {
  color: #ffffff;
}

.select-list {
  margin-top: 10px;
}

.ico-point {
  font-size: 30px;
}

.app-btn {
  height: auto;
  min-height: 10px;
  background-color: #fff;
  min-width: 0;
  border-radius: 12px;
  padding: 0;
}

.app-btn .v-btn__content {
  display: block !important;
  padding: 10px;
}

.v-application .secondary.app-btn {
  background-color: #f1f3f6 !important;
  border-color: #f1f3f6 !important;
  color: #3f3d56;
  box-shadow: none;
}
.v-application .primary.app-btn {
  background-color: #6dd984 !important;
  border-color: #6dd984 !important;
  color: #fff;
}

.app-btn .v-btn__content i {
  margin-bottom: 10px;
  border: 2px solid;
  border-radius: 50%;
}

.theCaleSect,
.btn-group {
  text-align: center;
}

/*/////////////////////////*/
.forCale {
  background-color: white;
  padding: 10px !important;
  overflow: hidden;
}

.forCale .names {
  background: #a5a6f6;
  border-radius: 10px;
}

.v-btn:not(.v-btn--round).v-size--default.app-btn {
  height: auto;

  min-width: auto;
  padding: 10px 10px !important;
}

/*/////////////////////////*/

.name-selecter-parent {
  border: 4px solid#A5A6F6;
  margin-bottom: 30px;
  border-radius: 10px;
  background: #5d5fef;
}

.name-selecter > button {
  width: 100%;
  position: relative;
}

.name-selecter > button i {
  position: absolute;
  right: 0;
}

/*history*/
.forlist.theme--light.v-list {
  background: #fff;
}

.forlist.theme--light.v-list .v-list-item {
  border-bottom: 1px solid #dadada;
}
</style>
